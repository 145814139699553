module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1200,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"slackalien-website","short_name":"slackalien","start_url":"/","background_color":"#339933","theme_color":"#339933","display":"standalone","icon":"src/images/logo/slackalien-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3ddaf8bdd8875a586fc8bebe72701849"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://slackalien.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"title":"SlackAlien","titleTemplate":"%s - SlackAlien","description":"Športno društvo SlackAlien","language":"sl","defaultOpenGraphImageWidth":1200,"defaultOpenGraphImageHeight":1200,"metaTags":[{"property":"keywords","content":"slackline,highline,visokica,ravnotežje"}],"openGraph":{"type":"website","locale":"sl_SI","url":"https://slackalien.com","title":"SlackAlien","site_name":"SlackAlien","description":"Športno društvo SlackAlien","images":[{"url":"https://slackalien.com/slackalien.png","width":1600,"height":900,"alt":"SlackAlien"}]},"twitter":{"cardType":"summary_large_image"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
